import type {Breakpoint} from '../ViewportSizeListener';
import {useViewportSize} from '../ViewportSizeListener';

const breakpoints = [{key: 'mobile', query: '(max-width: 700px)'}] as Array<
  Breakpoint<'mobile'>
>;
export const useIsMobile = () => {
  const view = useViewportSize(breakpoints);
  return view === 'mobile';
};
