import Section from '@pinecast/common/Section';
import {PageSubheader, SectionSubheader} from '@pinecast/common/Text';
import TooltipContainer from '@pinecast/common/TooltipContainer';
import type {Message} from '@pinecast/i18n';
import {defineMessages, FormattedMessage} from '@pinecast/i18n';
import {useCSS} from '@pinecast/styles';
import * as React from 'react';

import type {Directory, Podcastish} from './directories';
import {getFilteredDirectories, getOSList} from './directories';

const messages = defineMessages({
  title: {
    id: 'sl-spa.ListeNow.title',
    description: 'Title for section to listen to a podcast now',
    defaultMessage: 'Listen in an app',
  },
  appsForOS: {
    id: 'sl-spa.ListeNow.appsForOS',
    description:
      'Subheader for section to open a podcast in an app for the OS {os}',
    defaultMessage: 'Apps for {os}',
  },
  allApps: {
    id: 'sl-spa.ListeNow.allApps',
    description: 'Subheader for section to open a podcast in other apps',
    defaultMessage: 'Other apps',
  },

  os_iOS: {
    id: 'sl-spa.ListeNow.os.iOS',
    description: 'Name of iOS',
    defaultMessage: 'iOS',
  },
  os_android: {
    id: 'sl-spa.ListeNow.os.android',
    description: 'Name of Android OS',
    defaultMessage: 'Android',
  },
  os_web: {
    id: 'sl-spa.ListeNow.os.web',
    description: 'Name of "OS" when the user is online',
    defaultMessage: 'desktop',
  },
});

function renderDirectory(
  key: string,
  directory: Directory,
  icon: React.ReactNode,
  prefix: string,
) {
  return (
    <TooltipContainer
      tooltipContent={directory.name}
      key={key}
      offsetDistance={0}
      offsetSkidding={4}
    >
      <a
        aria-label={directory.name}
        rel="nofollow"
        href={`${prefix}/listen_in/${key}`}
        style={{display: 'flex'}}
        target={prefix !== window.location.pathname ? '_blank' : undefined}
      >
        {icon}
      </a>
    </TooltipContainer>
  );
}

type Props = {
  podcast: Podcastish;
  prefix?: string;
  showHeader?: boolean;
};

const ListenNow = ({
  podcast,
  prefix = window.location.pathname,
  showHeader = true,
}: Props) => {
  const css = useCSS();
  const filteredDirectories = getFilteredDirectories(podcast);
  const os = getOSList();

  return (
    <>
      {showHeader && (
        <PageSubheader>
          <FormattedMessage {...messages.title} />
        </PageSubheader>
      )}
      <Section>
        <SectionSubheader>
          <FormattedMessage
            {...messages.appsForOS}
            values={{
              os: (
                <FormattedMessage
                  {...((messages as Record<string, Message>)[`os_${os[0]}`] ||
                    messages.os_web)}
                />
              ),
            }}
          />
        </SectionSubheader>
        <div className={css({':not(:empty) > *': {marginRight: '12px'}})}>
          {filteredDirectories
            .filter(([k, v]) => v.recommendedFor.some(x => os.includes(x)))
            .map(([k, v]) => renderDirectory(k, v, v.icon, prefix))}
        </div>
        <SectionSubheader>
          <FormattedMessage {...messages.allApps} />
        </SectionSubheader>
        <div className={css({':not(:empty) > *': {marginRight: '8px'}})}>
          {filteredDirectories
            .filter(([k, v]) => !v.recommendedFor.some(x => os.includes(x)))
            .map(([k, v]) =>
              renderDirectory(
                k,
                v,
                React.cloneElement(v.icon, {size: 32}),
                prefix,
              ),
            )}
        </div>
      </Section>
    </>
  );
};

export default ListenNow;
