import * as React from 'react';
import type {StyleObject} from 'styletron-react';
import {useStyletron} from 'styletron-react';

import {lintContent} from './lint';
import type {CSS} from './types';

declare let Rollbar: any;

let lintCache: WeakSet<CSS>;
if (process.env.NODE_ENV !== 'production') {
  lintCache = new WeakSet<CSS>();
}

export const useCSS = () => {
  const [css] = useStyletron();

  return React.useCallback(
    (style: CSS, className?: string) => {
      // Linting
      if (process.env.NODE_ENV !== 'production') {
        if (!lintCache.has(style)) {
          lintContent(style);
          lintCache.add(style);
        }
      }

      let output = '';
      try {
        output = css(style as StyleObject);
      } catch (e) {
        Rollbar.error(`Could not render styles from CSS`, {error: e, style});
      }

      if (className) {
        if (output) {
          output += ' ';
        }
        output += className;
      }

      return output;
    },
    [css],
  );
};
