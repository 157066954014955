import type {ColorMap} from '@pinecast/styles';
import styled from '@pinecast/styles';

import {DEFAULT_FONT} from './constants';

export const PageTitle = styled('h1', {
  display: 'block',
  fontFamily: 'Monument Grotesk Bold',
  fontSize: 24,
  fontWeight: 400,
  lineHeight: 32,
  marginBottom: 24,
  marginTop: 48,
});

const PageHeaderStyles = (designColors: ColorMap) => ({
  color: designColors.headingBlue,
  display: 'block',
  fontSize: 24,
  fontFamily: 'Monument Grotesk Bold',
  fontWeight: 400,
  lineHeight: 28,
  marginBottom: 12,
  marginTop: 0,
  ':not(:empty) ~ .PageSubheader': {
    marginTop: 20,
  },
});
export const PageHeader = styled('h2', (_, designColors) =>
  PageHeaderStyles(designColors),
);
export const DesktopPageHeader = styled('h2', (_, designColors) => ({
  ...PageHeaderStyles(designColors),
  '@media (max-width: 600px)': {
    display: 'none',
  },
}));

export const PageSubheader = styled(
  'h3',
  (_, designColors) => ({
    color: designColors.contentText,
    display: 'block',
    fontFamily: 'Monument Grotesk Bold',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20,
    marginBottom: 4,
    marginTop: 0,
  }),
  {className: 'PageSubheader'},
);

export const DashboardTitle = styled(
  'h3',
  {
    display: 'block',
    fontFamily: 'Monument Grotesk Bold',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 20,
    marginTop: 0,
    marginBottom: 16,
    ':not(:empty) + .Paragraph': {
      marginTop: -4,
    },
    ':not(:empty) ~ .DashboardTitle': {
      marginTop: 40,
    },
  },
  {className: 'DashboardTitle'},
);

export const SectionSubheader = styled(
  'h4',
  (_, designColors) => ({
    color: designColors.headingBlue,
    fontSize: '12px',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: '4px',
    textTransform: 'uppercase',
    ':not(:empty) ~ .SectionSubheader': {
      marginTop: '20px',
    },
  }),
  {className: 'SectionSubheader'},
);

export const P = styled(
  'p',
  {
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 16,
    marginTop: 0,

    '@media (max-width: 400px)': {
      textAlign: 'justify',
    },
    ':not(:empty) + .Paragraph': {
      marginTop: -8,
    },
    ':last-child': {
      marginBottom: 0,
    },
  },
  {className: 'Paragraph'},
);

export const LegalP = styled(
  'p',
  {
    fontSize: 10,
    lineHeight: 13,
    marginBottom: 0,
    marginTop: 0,
    paddingRight: '20%',
    textAlign: 'justify',

    '@media (max-width: 400px)': {
      paddingRight: 0,
    },
    ':not(:empty) + .LegalP': {
      marginTop: 8,
    },
  },
  {className: 'LegalP'},
);

export const TextPill = styled('span', (_, designColors) => ({
  backgroundColor: designColors.contentBackground,
  borderRadius: 3,
  boxShadow: `0 0 0 2px ${designColors.borderGrayAccent}`,
  display: 'inline-flex',
  fontSize: '0.95em',
  margin: '0 2px',
  padding: '0 4px',
}));

export const HelpText = styled('p', {
  fontFamily: DEFAULT_FONT,
  fontSize: 12,
  marginTop: '12px',
  marginRight: 0,
  marginLeft: 0,
  marginBottom: '12px',
});

export const InlineCode = styled('code', (_, designColors) => ({
  // backgroundColor: designColors.background,
  backgroundColor: '#F9F199',
  borderRadius: 4,
  color: '#000',
  display: 'inline-block',
  fontFamily:
    'Monument Grotesk Semi-Mono, Consolas, Input, Courier, Courier New, monospace',
  fontWeight: 400,
  padding: '0 4px',
}));

export const Timestamp = styled('time', (_, designColors) => ({
  color: designColors.contentTextAccent,
  borderBottom: `1px dotted ${designColors.contentTextAccent}`,
  textDecoration: 'none',
}));
