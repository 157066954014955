import type {CommonProps} from './types';

const RightArrowSmall = ({
  color = 'currentColor',
  size = 10,
  ...rest
}: CommonProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 10 10"
    {...rest}
  >
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M.31 5H9.7M5.31 9.38L9.7 5 5.3.62"
    />
  </svg>
);

export default RightArrowSmall;
