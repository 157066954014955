import {PageSubheader} from '@pinecast/common/Text';
import type {ShareShortLink_podcast} from '@pinecast/gql/__generated_sl__/ShareShortLink';
import {defineMessages, FormattedMessage} from '@pinecast/i18n';

import EpisodePlayer from './EpisodePlayer';

const messages = defineMessages({
  title: {
    id: 'sl-spa.LatestEpisodePlayer.title',
    description:
      'The title of the latest episode section on the short link page',
    defaultMessage: 'Latest episode',
  },
});

type Props = {
  podcast: ShareShortLink_podcast;
};

const LatestEpisodePlayer = ({podcast}: Props) => {
  const episode = podcast.latestEpisode;
  if (!episode) {
    return null;
  }
  return (
    <>
      <PageSubheader style={{marginTop: '12px'}}>
        <FormattedMessage {...messages.title} />
      </PageSubheader>
      <EpisodePlayer episode={episode} />
    </>
  );
};

export default LatestEpisodePlayer;
