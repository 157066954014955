import styled, {useColors, useCSS} from '@pinecast/styles';
import * as React from 'react';

import {useUniqueID} from './hooks/useUniqueID';
import {ErrorIcon, Info} from './icons';
import Layer from './Layer';
import type {XAlign, YAlign} from './Positioner';
import {Positioner} from './Positioner';

type Size = 'normal' | 'large';

const maxWidths = {
  normal: 300,
  large: 500,
};

const isMobile = window.matchMedia('(max-width: 700px)');

const TooltipWrapper = styled(
  'aside',
  ({
    $active,
    $size,
    $xAlign,
    $yAlign,
  }: {
    $active: boolean | undefined;
    $size: Size;
    $xAlign: 'left' | 'right';
    $yAlign: 'top' | 'bottom';
  }) => ({
    color: '#fff',
    display: 'inline-flex',
    maxWidth: maxWidths[$size],
    opacity: $active ? 1 : 0,
    position: 'relative',
    transform: $active ? 'scale(1)' : 'scale(0.9)',
    transformOrigin: `${$xAlign} ${$yAlign === 'top' ? 'bottom' : 'top'}`,
    transition: 'opacity 0.2s, transform 0.2s',
    zIndex: 3,

    [$yAlign === 'bottom' ? 'paddingTop' : 'paddingBottom']: 8,

    '::after': {
      border: '0 solid rgba(0, 0, 0, 0.8)',
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 2,

      [$xAlign === 'left' ? 'left' : 'right']: 0,
      [$yAlign === 'bottom' ? 'top' : 'bottom']: 2,
      [$yAlign === 'bottom' ? 'borderBottom' : 'borderTop']:
        '6px solid rgba(0, 0, 0, 0.8)',
      [$xAlign === 'left' ? 'borderRight' : 'borderLeft']:
        '12px solid transparent',
    },
  }),
);

const defaultRenderWrapper = (props: React.HTMLAttributes<any>) => (
  <span {...props} style={{display: 'inline-flex', ...props.style}} />
);

type Props = {
  active?: boolean;
  children?: React.ReactNode;
  positionerStyle?: React.CSSProperties;
  preferX?: XAlign;
  preferY?: YAlign;
  renderWrapper?: (props: Record<string, any>) => React.ReactElement | null;
  size?: Size;
  style?: React.CSSProperties;
  tooltipContent: React.ReactNode;
  type?: 'label' | 'aux';
  offsetSkidding?: number;
  offsetDistance?: number;
};

const TooltipContainer = ({
  active = true,
  children,
  positionerStyle,
  preferX,
  preferY,
  renderWrapper,
  size = 'normal',
  style,
  tooltipContent,
  type = 'aux',
  offsetSkidding,
  offsetDistance,
}: Props) => {
  const id = useUniqueID('tt');
  const css = useCSS();

  const [hovering, setHovering] = React.useState(false);

  const handleEnter = React.useCallback(() => {
    setHovering(true);
  }, []);
  const handleLeave = React.useCallback(() => {
    setHovering(false);
  }, []);

  const content = (
    <Positioner
      content={React.useMemo(
        () => (
          <>{children}</>
        ),
        [children],
      )}
      describedBy={type === 'aux' ? id : undefined}
      labelledBy={type === 'label' ? id : undefined}
      preferX={preferX}
      preferY={preferY}
      style={positionerStyle}
      offsetSkidding={offsetSkidding}
      offsetDistance={offsetDistance}
    >
      {React.useCallback(
        (ref, styles, xAlign, yAlign) => (
          <Layer pointerEvents={false}>
            <div
              className={css({
                display: 'flex',
                justifyContent: xAlign === 'left' ? 'flex-start' : 'flex-end',
                width: `${maxWidths[size || 'normal']}px`,
              })}
              style={{...styles}}
              ref={ref}
            >
              <TooltipWrapper
                $active={hovering && active}
                $size={size || 'normal'}
                $xAlign={xAlign}
                $yAlign={yAlign}
              >
                <div
                  id={id}
                  className={css({
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    borderBottomLeftRadius: '3px',
                    borderBottomRightRadius: '3px',
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 0,
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                    zIndex: 2,
                    [`border${yAlign === 'bottom' ? 'Top' : 'Bottom'}${
                      xAlign === 'left' ? 'Left' : 'Right'
                    }Radius`]: 0,
                  })}
                  role="tooltip"
                >
                  {tooltipContent}
                </div>
              </TooltipWrapper>
            </div>
          </Layer>
        ),
        [active, css, hovering, id, size, tooltipContent],
      )}
    </Positioner>
  );

  return (renderWrapper || defaultRenderWrapper)({
    children: isMobile.matches && false ? children : content,
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
    style,
  });
};

export default TooltipContainer;

export const HelpIcon = (props: {
  children: React.ReactNode;
  iconSize?: number;
  size?: Size;
  style?: React.CSSProperties;
}) => {
  const designColors = useColors();
  return isMobile.matches ? null : (
    <TooltipContainer
      size={props.size}
      style={props.style}
      tooltipContent={props.children}
      type="label"
      offsetDistance={8}
    >
      <Info color={designColors.headingBlue} size={props.iconSize || 14} />
    </TooltipContainer>
  );
};

export const WarningIcon = (props: {
  children: React.ReactNode;
  iconSize?: number;
  size?: Size;
  style?: React.CSSProperties;
}) => {
  const designColors = useColors();
  return isMobile.matches ? null : (
    <TooltipContainer
      size={props.size}
      style={props.style}
      tooltipContent={props.children}
      type="label"
      offsetDistance={8}
    >
      <ErrorIcon
        color={designColors.borderRedAccent}
        size={props.iconSize || 14}
      />
    </TooltipContainer>
  );
};
