import type {CSS} from '@pinecast/styles';
import {useColors, useCSS} from '@pinecast/styles';
import * as React from 'react';

type Props = {
  children: React.ReactNode;
  spacing?: number;
  style?: CSS;
};

const Section = ({children, spacing, style}: Props) => {
  const css = useCSS();
  const designColors = useColors();
  return (
    <div
      className={css(
        {
          background: designColors.contentBackground,
          borderStyle: 'solid',
          borderColor: designColors.contentBorder,
          borderTopWidth: '1px',
          borderBottomWidth: '2px',
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderRadius: '6px',
          color: designColors.contentText,
          paddingTop: '12px',
          paddingRight: '12px',
          paddingBottom: '12px',
          paddingLeft: '12px',

          ':not(:empty) + .Section': {
            marginTop: `${spacing ?? 20}px`,
          },
          ':not(:empty) + .Callout': {
            marginTop: '20px',
          },
          ':not(:empty) > .Table': {
            marginBottom: '-8px',
            marginTop: '-8px',
          },

          ...style,
        },
        'Section',
      )}
    >
      {children}
    </div>
  );
};

export default Section;
