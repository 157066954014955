import {useIsDarkMode} from '@pinecast/common/hooks/useIsDarkMode';
import type {ShareEpisodeShortLink_episode} from '@pinecast/gql/__generated_sl__/ShareEpisodeShortLink';
import type {ShareShortLink_podcast_latestEpisode} from '@pinecast/gql/__generated_sl__/ShareShortLink';
import {defineMessages, useIntl} from '@pinecast/i18n';

const messages = defineMessages({
  title: {
    id: 'sl-spa.EpisodePlayer.title',
    description: 'Title of the episode player',
    defaultMessage: 'Episode player',
  },
});

type Props = {
  episode: ShareShortLink_podcast_latestEpisode | ShareEpisodeShortLink_episode;
};

const EpisodePlayer = ({episode}: Props) => {
  const intl = useIntl();
  const isDarkMode = useIsDarkMode();
  let host;
  if (process.env.NODE_ENV !== 'production') {
    host = 'http://localhost:8000';
  } else if (location.origin.includes('next.')) {
    host = 'https://next.pinecast.com';
  } else {
    host = 'https://pinecast.com';
  }
  return (
    <iframe
      frameBorder="0"
      height={61}
      src={`${host}/player/${episode.id}?theme=cardstock&dark=${
        isDarkMode ? 'true' : 'false'
      }&shortlink=true`}
      style={{border: 0}}
      title={intl.formatMessage(messages.title)}
      width="100%"
    />
  );
};

export default EpisodePlayer;
