import type {Sub} from '@pinecast/common/types';
import * as React from 'react';

import type {ColorMap} from './colors';
import {useColors} from './colors';
import {renderExampleMode} from './exampleMode';
import prepareStyle from './prepareStyle';
import type {CSS} from './types';
import {useCSS} from './useCSS';

export type HTMLProps = Omit<React.AllHTMLAttributes<any>, 'style'>;
export type StyledProps = {
  className?: string;
  __forceStyle?: React.CSSProperties;
  style?: CSS;
} & HTMLProps & {[key: string]: any};

function styled(elemType: string): React.ComponentType<StyledProps>;
function styled<T>(
  elemType: string,
  props: CSS | {[key: string]: string | number | CSS | undefined},
  defaultProps?: HTMLProps,
): React.ForwardRefExoticComponent<StyledProps>;
function styled<T>(
  elemType: string,
  props: (props: T, designColors: ColorMap) => CSS,
  defaultProps?: HTMLProps,
): React.ForwardRefExoticComponent<T & StyledProps>;

function styled<T extends Record<string, any>>(
  elemType: string,
  props?:
    | CSS
    | {[key in keyof CSS]: CSS[key] | CSS}
    | ((props: T, designColors: ColorMap) => CSS),
  defaultProps?: HTMLProps,
): React.ForwardRefExoticComponent<T & StyledProps> {
  // Basic validation
  if (process.env.NODE_ENV !== 'production') {
    if (elemType.toLowerCase() !== elemType) {
      throw new Error(
        `You aren't using lowercase letters for your element name. ${elemType.toLowerCase()} != ${elemType}`,
      );
    }
    if (elemType.includes('-')) {
      throw new Error(
        `You used a hyphen in your element name. No web components: ${elemType}`,
      );
    }
  }

  let preppedStyles: Sub<CSS> | null = null;
  if (props && typeof props === 'object') {
    preppedStyles = {...(props as Sub<CSS>)};
    prepareStyle(preppedStyles);
  }

  const forwarded = React.forwardRef<HTMLElement, T & StyledProps>(
    (passedProps: StyledProps, ref) => {
      const css = useCSS();
      const designColors = useColors();

      const {__forceStyle, className, style, ...ownProps} = Object.assign(
        {},
        defaultProps,
        passedProps,
      );

      let styleResult: Sub<CSS>;
      if (typeof props === 'function') {
        styleResult = {...props(ownProps as T, designColors), ...style};
        prepareStyle(styleResult);

        // Ignore $ props
        for (const key in ownProps) {
          if (key[0] === '$') {
            delete ownProps[key];
          }
        }
      } else if (!props) {
        styleResult = {...style};
        prepareStyle(styleResult);
      } else if (style) {
        styleResult = {...(props as CSS), ...style};
        prepareStyle(styleResult);
      } else if (preppedStyles) {
        styleResult = preppedStyles;
      } else {
        styleResult = {};
      }

      ownProps.ref = ref;
      if (__forceStyle) {
        ownProps.style = __forceStyle;
      }

      if (process.env.NODE_ENV !== 'production' && false) {
        return renderExampleMode(elemType, className, ownProps, styleResult);
      }

      const styletronClassNames = css(styleResult);
      if (className) {
        ownProps.className = `${styletronClassNames} ${className}`;
      } else {
        ownProps.className = styletronClassNames;
      }
      return React.createElement(elemType, ownProps);
    },
  );
  forwarded.displayName = `Styled(<${elemType}>)`;
  return forwarded as any;
}

export default styled;
