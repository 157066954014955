import Group, {VerticalGroup} from '@pinecast/common/Group';
import {RightArrowSmall} from '@pinecast/common/icons';
import Link from '@pinecast/common/Link';
import {DashboardTitle} from '@pinecast/common/Text';
import {PodcastDirectoryEnum} from '@pinecast/gql/__generated_sl__/globalTypes';
import type {ShareShortLink_podcast} from '@pinecast/gql/__generated_sl__/ShareShortLink';
import {defineMessages, FormattedMessage} from '@pinecast/i18n';

const messages = defineMessages({
  links: {
    id: 'sl-spa.PodcastLinks.links',
    description: 'Title of links sidebar',
    defaultMessage: 'Show links',
  },
  homepage: {
    id: 'sl-spa.PodcastLinks.homepage',
    description: 'Homepage link',
    defaultMessage: 'Website',
  },
  rss: {
    id: 'sl-spa.PodcastLinks.rss',
    description: 'RSS feed link',
    defaultMessage: 'RSS feed',
  },
  podchaser: {
    id: 'sl-spa.PodcastLinks.podchaser',
    description: 'Podchaser listing link',
    defaultMessage: 'Podchaser',
  },
  tipJar: {
    id: 'sl-spa.PodcastLinks.tipJar',
    description: 'Tip jar donation link',
    defaultMessage: 'Send a tip',
  },
  feedback: {
    id: 'sl-spa.PodcastLinks.feedback',
    description: 'Tip jar donation link',
    defaultMessage: 'Leave a comment',
  },
});

type Props = {
  podcast: ShareShortLink_podcast;
};

const PodcastLinks = ({podcast}: Props) => {
  const podchaserListing = podcast.directoryListings.find(
    x => x.service === PodcastDirectoryEnum.PODCHASER,
  );
  return (
    <>
      <DashboardTitle style={{marginBottom: '8px', marginTop: '8px'}}>
        <FormattedMessage {...messages.links} />
      </DashboardTitle>
      <VerticalGroup spacing={4}>
        <Link href={podcast.homepage}>
          <Group spacing={4} centerChildren>
            <FormattedMessage {...messages.homepage} />
            <RightArrowSmall />
          </Group>
        </Link>
        <Link
          href={`https://pinecast.com/feed/${podcast.slug}`}
          target="_blank"
        >
          <Group spacing={4} centerChildren>
            <FormattedMessage {...messages.rss} />
            <RightArrowSmall />
          </Group>
        </Link>
        {podchaserListing && (
          <Link
            href={`${window.location.origin}/s/${podcast.slug}/listen_in/PODCHASER`}
            target="_blank"
          >
            <Group spacing={4} centerChildren>
              <FormattedMessage {...messages.podchaser} />
              <RightArrowSmall />
            </Group>
          </Link>
        )}
        {podcast.tipJarActive && (
          <Link href={`https://tips.pinecast.com/jar/${podcast.slug}`}>
            <Group spacing={4} centerChildren>
              <FormattedMessage {...messages.tipJar} />
              <RightArrowSmall />
            </Group>
          </Link>
        )}
        {podcast.publicFeedbackActive && (
          <Link href={`https://pinecast.com/feedback/${podcast.slug}`}>
            <Group spacing={4} centerChildren>
              <FormattedMessage {...messages.feedback} />
              <RightArrowSmall />
            </Group>
          </Link>
        )}
      </VerticalGroup>
    </>
  );
};

export default PodcastLinks;
