import styled from './styled';
import type {CSS, CSSProperties} from './types';

export default styled;
export type {CSS, CSSProperties};
export {styled};
export {isFirefox} from './browserDetection';
export type {ColorMap} from './colors';
export {DarkModeProvider, useColors, useSetBackground} from './colors';
export {useCSS} from './useCSS';

function unCalc(
  value: string | number,
  multiplication: boolean = false,
): string {
  if (typeof value === 'number') {
    return multiplication ? value.toString() : `${value}px`;
  }
  if (value.startsWith('calc(')) {
    return value.substr(4);
  }
  return value;
}

export function calc(
  strings: TemplateStringsArray,
  ...bits: Array<string | number>
): string {
  if (!strings[0].startsWith('calc(')) {
    throw new Error('calc template literal must begin with `calc(`');
  }
  if (!strings[strings.length - 1].endsWith(')')) {
    throw new Error('calc template literal must end with `)`');
  }
  return strings
    .map((s, i) => {
      if (i) {
        return (
          unCalc(
            bits[i - 1],
            s.includes('*') ||
              s.includes('/') ||
              strings[i - 1].includes('*') ||
              strings[i - 1].includes('/'),
          ) + s
        );
      }
      return s;
    })
    .join('');
}
