import type {Sub} from '@pinecast/common/types';

import {isChrome, isFirefox, isSafari} from './browserDetection';
import type {CSS} from './types';
import {applyUnit} from './unitlessValues';

const prepareStyle = (styleResult: Sub<CSS>) => {
  if (isSafari) {
    delete styleResult['::-webkit-color-swatch-wrapper'];
    delete styleResult['::-webkit-color-swatch'];
  }
  for (const key in styleResult) {
    const type = typeof styleResult[key];

    // Browser compatibility fixes
    if (isChrome || isSafari) {
      if (key.includes('-moz') || key.includes('::-moz-')) {
        delete styleResult[key];
        continue;
      }
    } else if (isFirefox) {
      if (key.includes('-webkit')) {
        delete styleResult[key];
        continue;
      }
    }

    if (type === 'undefined') {
      delete styleResult[key];
      continue;
    } else if (type === 'object' && !Array.isArray(styleResult[key])) {
      prepareStyle(styleResult[key]);
      continue;
    }

    if (type === 'number') {
      styleResult[key] = applyUnit(key, styleResult[key]);
    } else if (Array.isArray(styleResult[key])) {
      styleResult[key] = styleResult[key].map((x: unknown) =>
        applyUnit(key, x),
      );
    }

    if (isFirefox && key === 'userSelect') {
      styleResult['-moz-user-select'] = styleResult[key];
    }
  }

  // Edge and Safari don't handle subpixel box shadows
  if (typeof styleResult.boxShadow === 'string' && !isChrome && !isFirefox) {
    styleResult.boxShadow = styleResult.boxShadow.replace(/0\.5px/gi, '1px');
  }
};
export default prepareStyle;
